import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { MediaModel } from '@fc-vehicles/service/media-model';
import { ThumbsUrlPipe } from '@fc-shared/pipes/thumbnails-pipe/thumbnails.pipe';
import { NoteFileProgress } from '@fc-vehicles/vehicle-notes/models/note-file.progress';

@Injectable({
  providedIn: 'root',
})
export class VehicleNotesMediaService {
  constructor(
    private http: HttpClient,
    private thumbPipe: ThumbsUrlPipe,
  ) {}

  loadNoteFiles(vehicleNoteId: number): Observable<MediaModel[]> {
    return this.http.get<MediaModel[]>(
      environment.apiUrl + `api/files/vehicle_notes/${vehicleNoteId}/files/`,
    );
  }

  loadNotesFiles(vehicleNoteIds: number[]): Observable<MediaModel[]> {
    if (vehicleNoteIds.length === 0)
      return new Observable<MediaModel[]>((subscriber) => subscriber.next([]));
    const requests = vehicleNoteIds.map((id) => this.loadNoteFiles(id));
    return forkJoin(requests).pipe(
      map((files) => files.reduce((acc, file) => [...acc, ...file], [])),
    );
  }

  addAttachment(
    vehicleNoteId: number,
    files: MediaModel[],
  ): Observable<NoteFileProgress[]> {
    const requests = files.map((file) => {
      const formData = new FormData();
      formData.append('original', file.original);
      formData.append('name', file.name);
      formData.append('fileType', file.fileType);
      return this.http
        .post<any>(
          environment.apiUrl +
            `api/files/vehicle_notes/${vehicleNoteId}/files/`,
          formData,
          {
            reportProgress: true,
            observe: 'events',
          },
        )
        .pipe(
          filter(
            (event) =>
              event.type === HttpEventType.UploadProgress ||
              event.type === HttpEventType.Response,
          ),
          map((event) => {
            if (event.type === HttpEventType.UploadProgress) {
              return {
                progress: Math.round((event.loaded / event.total) * 100),
                fileId: file.lastModified,
                file: null,
              };
            } else if (event.type == HttpEventType.Response) {
              return {
                progress: 100,
                fileId: file.lastModified,
                file: event.body,
              };
            }
          }),
        );
    });

    return combineLatest(requests);
  }

  updateFile(vehicleNoteId: number, file: MediaModel): Observable<MediaModel> {
    const formData = new FormData();
    formData.append('name', file.name);
    if (file.description) formData.append('description', file.description);
    formData.append('fileType', file.fileType);
    return this.http.patch<MediaModel>(
      environment.apiUrl +
        `api/files/vehicle_notes/${vehicleNoteId}/files/${file.id}/`,
      formData,
    );
  }

  updateFiles(
    vehicleNoteId: number,
    files: MediaModel[],
  ): Observable<MediaModel[]> {
    const requests = files.map((file) => this.updateFile(vehicleNoteId, file));
    return combineLatest(requests);
  }

  deleteFile(vehicleNoteId: number, fileId: number): Observable<void> {
    return this.http.delete<void>(
      environment.apiUrl +
        `api/files/vehicle_notes/${vehicleNoteId}/files/${fileId}/`,
    );
  }

  deleteFiles(filesIds: number[], vehicleNoteId: number): Observable<void[]> {
    const requests = filesIds.map((fileId) =>
      this.deleteFile(vehicleNoteId, fileId),
    );
    return combineLatest(requests);
  }

  getMediaPreview(
    file: MediaModel,
    size?: { width: number; height: number },
  ): string {
    if (file.originalImg) {
      if (size) {
        return this.thumbPipe.transform(
          file.originalImg,
          size.width,
          size.height,
        );
      }
      return file.originalImg;
    }
    if (file.mimeType === 'image/jpeg' || file.mimeType === 'image/png') {
      return file.original;
    }
    return this.getMediaPlaceholder(file);
  }

  getMediaType(file: MediaModel): MediaType {
    if (!file.mimeType) return 'other';
    if (file.mimeType.includes('pdf')) {
      return 'pdf';
    } else if (
      file.mimeType.includes('doc') ||
      file.mimeType.includes('word')
    ) {
      return 'doc';
    } else if (file.mimeType.includes('exel')) {
      return 'exel';
    } else if (file.mimeType.includes('image')) {
      return 'image';
    }
    return 'other';
  }

  getMediaPlaceholder(file: MediaModel): string {
    if (
      !file.mimeType &&
      imageTypeExtensions.some((ext) =>
        file.original.toLowerCase().includes(ext),
      )
    ) {
      // if server is still processing image and we don't have mimeType show original
      return file.original;
    }

    if (!file.mimeType) return 'assets/core-icons/other.svg';
    if (file.mimeType.includes('pdf')) {
      return 'assets/core-icons/pdf.svg';
    } else if (
      file.mimeType.includes('doc') ||
      file.mimeType.includes('word')
    ) {
      return 'assets/core-icons/doc.svg';
    } else if (file.mimeType.includes('exel')) {
      return 'assets/core-icons/xls.svg';
    } else if (file.mimeType.includes('image')) {
      return 'assets/core-icons/image.svg';
    }
    return 'assets/core-icons/other.svg';
  }
}

export type MediaType = 'image' | 'pdf' | 'doc' | 'exel' | 'other';
export const imageTypeExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
